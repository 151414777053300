<template>
  <app-timeline class="mt-2 mr-1">
    <app-timeline-item
      v-for="(row, index) in flow"
      :key="index"
      no-body
      icon="UserIcon"
      variant="primary"
    >
      <div
        v-if="[1,2].includes(Number(visit.sw070s29)) && typeof infoAgentExternal !== 'undefined' && infoAgentExternal.nome !== '' && ['comp'].includes(row.type_tipo_user_impulso)"
        class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
      >
        <h6>{{ row.desc_tipo_user_impulso }} - {{ $t('Agente externo') }}: {{ infoAgentExternal.nome }}  -  {{ $t('Consultor') }}: {{ row.consultor }} - <small class="text-muted">{{ formatDate(row.sw131s13) }}</small></h6>
      </div>
      <div
        v-else
        class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
      >
        <h6>{{ row.desc_tipo_user_impulso }} - {{ $t('Consultor') }} - {{ row.consultor }} - <small class="text-muted">{{ formatDate(row.sw131s13) }}</small></h6>
      </div>
      <div
        v-if="Number(row.sw131s21) === 1"
        class="justify-content align-items-start"
      >
        <b-row class="mb-1">
          <b-col
            md="12"
          >
            <div class="mb-sm-0">
              <span class="text-muted mb-50 d-block">{{ $t('Negócio') }}</span>
              <span>{{ row.sw131s03s }} </span>
              <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(row.sw131s04) }}</strong>
              <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(row.sw131s04) }}{{ listing.currencyR }}</strong>
            </div>
          </b-col>
        </b-row>
        <div v-if="(Number(row.sw131s11) === 13)">
          <b-row>
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Recusada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Ação tomada pelo {cargo}', { cargo: `${cargos.HM}` }) }} {{ row.sw131s23s }}</span>
                <span>{{ row.sw131s17 }} </span>
              </div>
            </b-col>
          </b-row>
          <div v-if="Number(row.sw131s11) === 13 && row.sw131s11 !== null && row.sw131s11 !== ''">
            <b-row
              v-for="(file, indexF) in row.files"
              :key="indexF"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                  <strong>
                    <b-link
                      :href="urlDocs + file.sw141s03"
                      target="_blank"
                      class="text-break-word"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-25"
                      />
                      {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                    </b-link>
                  </strong>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="(Number(row.sw131s11) === 14)">
          <b-row>
            <b-col
              md="2"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Aprovada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Ação tomada pelos {cargo}', { cargo: `${cargos.HMs}` }) }}</span>
                <span>{{ $t('A proposta foi aprovada e a angariação passou para o estado de reservado.') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="(Number(row.sw131s11) === 15 || Number(row.sw131s11) === 16)">
          <b-row>
            <b-col
              md="2"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Aprovada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Ação tomada pelos {cargo}', { cargo: `${cargos.HMs}` }) }}</span>
                <span v-if="Number(row.sw131s11) === 15">{{ $t('O {cargo} {nome1} aprovou a proposta do lado da {hub}. Pendente da aprovação de um {cargo} da {hub2}.', { cargo: `${cargos.HM}`, nome1: `${row.sw131s23s}`, hub: `${row.sw131s23HUB}`, hub2: `${row.sw130s05HUB}` }) }}</span>
                <span v-if="Number(row.sw131s11) === 16">{{ $t('O {cargo} {nome1} aprovou a proposta do lado da {hub}. Pendente da aprovação de um {cargo} da {hub2}.', { cargo: `${cargos.HM}`, nome1: `${row.sw131s23s}`, hub: `${row.sw131s23HUB}`, hub2: `${row.sw130s13HUB}` }) }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="Number(row.sw131s27) === 0 && iWhoami === 4 && row.sw131s31 === null && (row.sw131s34 === null || ![0].includes(Number(row.sw131s34))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                @click="finalApproveProposal"
              >
                <span>{{ $t('Aprovar') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="Number(row.sw131s27) === 0 && iWhoami === 4 && row.sw131s31 === null && (row.sw131s34 === null || ![0].includes(Number(row.sw131s34))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="finalRefuseProposal"
              >
                <span>{{ $t('Recusar') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="Number(row.sw131s27) === 0 && iWhoami === 3 && row.sw131s34 === null && (row.sw131s31 === null || ![0].includes(Number(row.sw131s31))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                @click="finalApproveProposal"
              >
                <span>{{ $t('Aprovar') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="Number(row.sw131s27) === 0 && iWhoami === 3 && row.sw131s34 === null && (row.sw131s31 === null || ![0].includes(Number(row.sw131s31))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="finalRefuseProposal"
              >
                <span>{{ $t('Recusar') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="(Number(row.sw131s11) === 1 || Number(row.sw131s11) === 4)">
          <div v-if="(Number(row.sw131s35) === 1 || Number(row.sw131s35) === 2)">
            <b-row>
              <b-col
                md="2"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                  <span>{{ $t('Aprovada') }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="typeof row.sw131s27 !== 'undefined' && row.sw131s27 !== null && row.sw131s27 !== ''"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <h6>{{ $t('A proposta foi aceite e a angariação passou para o estado de reservado.') }}</h6>
              </b-col>
            </b-row>
            <b-row
              v-for="(file, indexF) in row.files"
              :key="indexF"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                  <strong>
                    <b-link
                      :href="urlDocs + file.sw141s03"
                      target="_blank"
                      class="text-break-word"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-25"
                      />
                      {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                    </b-link>
                  </strong>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && (iWhoami === 4 || iWhoami === 5) && (row.sw131s31 === null || row.sw131s31 === '')
                && (row.sw131s34 === '' || row.sw131s34 === null || ![0].includes(Number(row.sw131s34))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-success"
                  @click="finalApproveProposal"
                >
                  <span>{{ $t('Aprovar') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && (iWhoami === 4 || iWhoami === 5) && (row.sw131s31 === null || row.sw131s31 === '')
                && (row.sw131s34 === '' || row.sw131s34 === null || ![0].includes(Number(row.sw131s34))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="finalRefuseProposal"
                >
                  <span>{{ $t('Recusar') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && iWhoami === 3 && (row.sw131s34 === null || row.sw131s34 === '')
                && (row.sw131s31 === '' || row.sw131s31 === null || ![0].includes(Number(row.sw131s31))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-success"
                  @click="finalApproveProposal"
                >
                  <span>{{ $t('Aprovar') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && iWhoami === 3 && row.sw131s34 === null && (row.sw131s31 === null || ![0].includes(Number(row.sw131s31))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="finalRefuseProposal"
                >
                  <span>{{ $t('Recusar') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col
                md="2"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                  <span>{{ $t('Aceite') }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="typeof row.sw131s27 !== 'undefined' && row.sw131s27 !== null && row.sw131s27 !== ''"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <h6>{{ $t('A proposta foi aceite e a angariação passou para o estado de reservado.') }}</h6>
              </b-col>
            </b-row>
            <b-row
              v-else
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <h6>{{ $t('A proposta foi aceite mas tem de ser aprovada pelo {cargo}', { cargo: `${cargos.HMs}` }) }}</h6>
              </b-col>
            </b-row>
            <b-row
              v-if="typeof row.HUB !== 'undefined' && row.HUB !== null && row.HUB !== ''"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Contato dos {cargo} da', { cargo: `${cargos.HMs}` }) }} {{ row.HUB }}</span>
                  <p
                    v-for="(contato, indexC) in row.contactosHMs"
                    :key="indexC"
                  >
                    {{ contato.nome }}: {{ contato.telefone }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-for="(file, indexF) in row.files"
              :key="indexF"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                  <strong>
                    <b-link
                      :href="urlDocs + file.sw141s03"
                      target="_blank"
                      class="text-break-word"
                    >
                      <feather-icon
                        icon="DownloadIcon"
                        class="mr-25"
                      />
                      {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                    </b-link>
                  </strong>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && (iWhoami === 4 || iWhoami === 5) && (row.sw131s31 === null || row.sw131s31 === '')
                && (row.sw131s34 === '' || row.sw131s34 === null || ![0].includes(Number(row.sw131s34))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-success"
                  @click="finalApproveProposal"
                >
                  <span>{{ $t('Aprovar') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && (iWhoami === 4 || iWhoami === 5) && (row.sw131s31 === null || row.sw131s31 === '')
                && (row.sw131s34 === '' || row.sw131s34 === null || ![0].includes(Number(row.sw131s34))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="finalRefuseProposal"
                >
                  <span>{{ $t('Recusar') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && iWhoami === 3 && (row.sw131s34 === null || row.sw131s34 === '')
                && (row.sw131s31 === '' || row.sw131s31 === null || ![0].includes(Number(row.sw131s31))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-success"
                  @click="finalApproveProposal"
                >
                  <span>{{ $t('Aprovar') }}</span>
                </b-button>
              </b-col>
            </b-row>
            <b-row
              v-if="Number(row.sw131s27) === 0 && iWhoami === 3 && (row.sw131s34 === null || row.sw131s34 === '')
                && (row.sw131s31 === '' || row.sw131s31 === null || ![0].includes(Number(row.sw131s31))) && Number(index) === 0 && [5, 9, 10, 13].includes(Number(currentUser.id_role))"
              class="mt-1"
            >
              <b-col
                md="12"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="finalRefuseProposal"
                >
                  <span>{{ $t('Recusar') }}</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="Number(row.sw131s11) === 10">
          <b-row
            v-if="iWhoami === 1"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O consultor angariador tem em negociação, mais propostas para este imóvel. Aguarde a aprovação do seu lado e não assuma como reservado.') }}</h6>
            </b-col>
          </b-row>
          <b-row
            v-if="iWhoami === 2"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O comprador aceitou a proposta. A reserva da angariação está pendente da sua aprovação, por ter mais do que uma em negociação.') }}</h6>
            </b-col>
          </b-row>
          <b-row
            v-if="iWhoami === 2 && Number(index) === 0"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O proprietário') }}:</h6>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                class="mb-1"
                @click="acceptProposal(1)"
              >
                <span>{{ $t('Aceitou') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-primary"
                class="mb-1"
                @click="counterProposal"
              >
                <span>{{ $t('Contra-proposta') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="refuseProposal(1)"
              >
                <span>{{ $t('Recusou') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="iWhoami === 5 && Number(index) === 0"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O comprador aceitou a proposta. A reserva da angariação está pendente da sua aprovação, por ter mais do que uma em negociação.') }}</h6>
            </b-col>
          </b-row>
          <b-row
            v-if="iWhoami === 5 && Number(index) === 0"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O proprietário') }}:</h6>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                class="mb-1"
                @click="acceptProposal(1)"
              >
                <span>{{ $t('Aceitou') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-primary"
                class="mb-1"
                @click="counterProposal"
              >
                <span>{{ $t('Contra-proposta') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="refuseProposal(1)"
              >
                <span>{{ $t('Recusou') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-else
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O comprador aceitou a proposta. A reserva da angariação está pendente da aprovação do angariador, por ter mais do que uma proposta em negociação.') }}</h6>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="Number(row.sw131s11) === 2 || Number(row.sw131s11) === 6">
          <b-row>
            <b-col
              md="2"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Recusada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="row.sw131s17.length > 0"
            class="mt-1"
          >
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Motivo') }}</span>
                <span>{{ row.sw131s17 }} </span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="Number(row.sw131s11) === 3 || Number(row.sw131s11) === 5">
          <b-row v-if="row.sw131s17.length > 0">
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Motivo') }}</span>
                <span>{{ row.sw131s17 }} </span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="(row.sw131s11 === '' || row.sw131s11 === null || Number(row.sw131s11) === 5 || Number(row.sw131s11) === 7) && (iWhoami === 2 || iWhoami === 5) && Number(index) === 0"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O proprietário') }}:</h6>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                class="mb-1"
                @click="acceptProposal(1)"
              >
                <span>{{ $t('Aceitou') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-primary"
                class="mb-1"
                @click="counterProposal"
              >
                <span>{{ $t('Contra-proposta') }}</span>
              </b-button>
            </b-col>
            <b-col
              v-if="Number(proposal.sw130s12) !== 17"
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="refuseProposal(1)"
              >
                <span>{{ $t('Recusou') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="(Number(row.sw131s11) === 3 || Number(row.sw131s11) === 8) && (iWhoami === 1 || iWhoami === 5) && Number(index) === 0"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O comprador') }}:</h6>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                class="mb-1"
                @click="acceptProposal(2)"
              >
                <span>{{ $t('Aceitou') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-primary"
                class="mb-1"
                @click="counterProposal"
              >
                <span>{{ $t('Contra-proposta') }}</span>
              </b-button>
            </b-col>
            <b-col
              v-if="Number(proposal.sw130s12) !== 17"
              md="2"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="refuseProposal(2)"
              >
                <span>{{ $t('Recusou') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="Number(row.sw131s11) === 9 || Number(row.sw131s11) === 11">
          <b-row>
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Cancelada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="row.sw131s17.length > 0">
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Motivo') }}</span>
                <span>{{ row.sw131s17 }} </span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="Number(row.sw131s11) === 22">
          <b-row>
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Cancelada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Motivo') }}</span>
                <span>{{ $t('Proposta cancelada por não aceitação do processo de venda, por parte do {cargo} do angariador', { cargo: `${cargos.HM}` }) }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="Number(row.sw131s11) === 23">
          <b-row>
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Cancelada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Ação tomada através do processo de venda pelo {cargo}', { cargo: `${cargos.HM}` }) }} {{ row.sw131s23s }}</span>
                <span>{{ row.sw131s17 }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="Number(row.sw131s11) === 12">
          <b-row>
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Reserva') }}</span>
                <span>{{ $t('Cancelada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Ação tomada pelo {cargo}', { cargo: `${cargos.HM}` }) }} {{ row.sw131s23s }}</span>
                <span>{{ row.sw131s17 }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="Number(row.sw131s11) === 24">
          <b-row>
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Proposta') }}</span>
                <span>{{ $t('Pendente de pagamento') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Motivo') }}</span>
                <span>{{ $t('A aceitação da proposta está pendente do pagamento da caução por parte do cliente.') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="row.zomenow_proposal_info !== null && row.zomenow_proposal_info !== ''"
            class="mt-1"
          >
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Estado de aceitação das condições da proposta') }}</span>
                <span
                  v-if="row.zomenow_confirm === null || row.zomenow_confirm === ''"
                  v-b-tooltip
                  :title="$t('Para que o cliente avance para a fase de pagamento, é necessário que o mesmo confirme as condições da proposta, através do link enviado.')"
                >
                  {{ $t('Não confirmado.') }}
                </span>
                <span v-else>{{ $t('Confirmado.') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="row.zomenow_proposal_info !== null && row.zomenow_proposal_info !== ''"
            class="mt-1"
          >
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('URL de validação enviado para o cliente') }}</span>
                <span>{{ row.zomenow_linkvalidacao }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div
        v-else
        class="justify-content align-items-start"
      >
        <div v-if="Number(row.sw131s11) === 20">
          <b-row>
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Reserva') }}</span>
                <span>{{ $t('Confirmada') }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Negócio') }}</span>
                <span>{{ row.sw131s03s }} </span>
                <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(row.sw131s04) }}</strong>
                <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(row.sw131s04) }}{{ listing.currencyR }}</strong>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Ação tomada pelo {cargo}', { cargo: `${cargos.HM}` }) }} {{ row.sw131s23s }}</span>
                <span>{{ $t('A reserva foi confirmada com sucesso, e a angariação mantem o estado reservado.') }}</span>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <b-row class="mb-1">
            <b-col
              v-if="row.sw131s24 !== null && row.sw131s24 !== ''"
              md="2"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Comissão proposta') }}</span>
                <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(row.sw131s24) }} + {{ $t('IVA') }}</strong>
                <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(row.sw131s24) }}{{ listing.currencyR }} + {{ $t('IVA') }}</strong>
              </div>
            </b-col>
            <b-col
              v-if="row.sw131s36 !== null && row.sw131s36 !== ''"
              md="2"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Caução') }}</span>
                <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(row.sw131s36) }}</strong>
                <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(row.sw131s36) }}{{ listing.currencyR }}</strong>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Negócio') }}</span>
                <span>{{ row.sw131s03s }} </span>
                <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(row.sw131s04) }}</strong>
                <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(row.sw131s04) }}{{ listing.currencyR }}</strong>
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="Number(row.sw131s11) === 17 || Number(row.sw131s11) === 18">
          <b-row>
            <b-col md="12">
              <h6>{{ row.message }}</h6>
            </b-col>
          </b-row>
        </div>
        <div v-else>
          <div v-if="Number(row.sw131s03) !== 2 && Number(row.sw131s11) !== 20">
            <b-row
              v-if="typeof row.sw131s06 !== 'undefined' && row.sw131s06 !== null && row.sw131s06 !== ''"
              class="mt-1"
            >
              <b-col
                md="3"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Entrega no contrato de promessa de compra e venda') }}</span>
                  <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(row.sw131s06) }}</strong>
                  <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(row.sw131s06) }}{{ listing.currencyR }}</strong>
                </div>
              </b-col>
              <b-col
                md="2"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Data') }}</span>
                  <span>{{ row.sw131s07 }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-for="(reforco, indexR) in row.aReforce"
              :key="indexR"
              class="mt-1"
            >
              <b-col
                md="3"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Reforço de sinal') }} {{ (indexR+1) }}</span>
                  <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(reforco.sw132s03) }}</strong>
                  <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(reforco.sw132s03) }}{{ listing.currencyR }}</strong>
                </div>
              </b-col>
              <b-col
                md="2"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Data') }}</span>
                  <span>{{ reforco.sw132s04 }}</span>
                </div>
              </b-col>
            </b-row>

            <b-row
              v-if="row.sw131s26 !== null && row.sw131s26 !== ''"
              class="mt-1"
            >
              <b-col
                md="3"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Valor a entregar no ato da escritura') }}</span>
                  <strong v-if="listing.currencyL !== null && listing.currencyL !== ''">{{ listing.currencyL }}{{ formatPrice(row.sw131s26) }}</strong>
                  <strong v-if="listing.currencyR !== null && listing.currencyR !== ''">{{ formatPrice(row.sw131s26) }}{{ listing.currencyR }}</strong>
                </div>
              </b-col>
              <b-col
                md="2"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Data') }}</span>
                  <span>{{ row.sw131s28 }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="(row.sw131s08 !== null && row.sw131s08 !== '' && row.sw131s08.length > 0) || Number(row.sw131s03) == 20">
            <b-row class="mt-1">
              <b-col
                md="12"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Condições complementares') }}</span>
                  <span>{{ row.sw131s08 }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="typeof row.sw131s24 !== 'undefined' && row.sw131s24 !== null && row.sw131s24 !== '' && typeof row.sw131s17 !== 'undefined' && row.sw131s17 !== null && row.sw131s17 !== ''">
            <b-row class="mt-1">
              <b-col
                md="12"
              >
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">{{ $t('Justificação para o pedido de alteração de comissão') }}</span>
                  <span>{{ row.sw131s17 }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row
            v-for="(file, indexF) in row.files"
            :key="indexF"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <div class="mb-1 mb-sm-0">
                <span class="text-muted mb-50 d-block">{{ $t('Documento') }}</span>
                <strong>
                  <b-link
                    :href="urlDocs + file.sw141s03"
                    target="_blank"
                    class="text-break-word"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-25"
                    />
                    {{ file.sw141s03 }}<span v-if="file.sw141s04 !== null && file.sw141s04 !== ''"> - {{ file.sw141s04 }}</span>
                  </b-link>
                </strong>
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="(typeof row.sw131s11 === 'undefined' || row.sw131s11 === '' || row.sw131s11 === null || Number(row.sw131s11) === 5 || Number(row.sw131s11) === 7) && (iWhoami === 2 || iWhoami === 5) && Number(index) === 0"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h5>{{ $t('O proprietário') }}:</h5>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                class="mb-1"
                @click="acceptProposal(1)"
              >
                <span>{{ $t('Aceitou') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-primary"
                class="mb-1"
                @click="counterProposal"
              >
                <span>{{ $t('Contra-proposta') }}</span>
              </b-button>
            </b-col>
            <b-col
              v-if="Number(proposal.sw130s12) !== 17"
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="refuseProposal(1)"
              >
                <span>{{ $t('Recusou') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="(Number(row.sw131s11) === 3 || Number(row.sw131s11) === 8) && (iWhoami === 1 || iWhoami === 5) && Number(index) === 0"
            class="mt-1"
          >
            <b-col
              md="12"
            >
              <h6>{{ $t('O comprador') }}:</h6>
            </b-col>
            <b-col
              md="12"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                class="mb-1"
                @click="acceptProposal(2)"
              >
                <span>{{ $t('Aceitou') }}</span>
              </b-button>
            </b-col>
            <b-col
              md="12"
              class="mt-1"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-primary"
                class="mb-1"
                @click="counterProposal"
              >
                <span>{{ $t('Contra-proposta') }}</span>
              </b-button>
            </b-col>
            <b-col
              v-if="Number(proposal.sw130s12) !== 17"
              md="12"
              class="mt-1"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="refuseProposal(2)"
              >
                <span>{{ $t('Recusou') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BButton, BLink,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import { formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    AppTimeline,
    AppTimelineItem,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    flow: {
      type: Array,
      required: true,
    },
    proposal: {
      type: Object,
      required: true,
    },
    listing: {
      type: Object,
      required: true,
    },
    visit: {
      type: Object,
      required: true,
    },
    infoAgentExternal: {
      type: Object,
      required: true,
    },
    cargos: {
      type: Object,
      required: true,
    },
    iWhoami: {
      type: Number,
      required: true,
      default: 0,
    },
    acceptProposal: {
      type: Function,
      required: true,
    },
    refuseProposal: {
      type: Function,
      required: true,
    },
    counterProposal: {
      type: Function,
      required: true,
    },
    urlDocs: {
      type: String,
      required: true,
      default: '',
    },
    finalApproveProposal: {
      type: Function,
      required: true,
    },
    finalRefuseProposal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
  },
  methods: {
    formatDate(dta) {
      const d = new Date(dta)

      let dias = d.getDate()
      let mes = d.getMonth() + 1

      if (dias < 10) {
        dias = `0${dias}`
      }

      if (mes < 10) {
        mes = `0${mes}`
      }

      let horas = d.getHours()
      let mintues = d.getMinutes()

      if (horas < 10) {
        horas = `0${horas}`
      }

      if (mintues < 10) {
        mintues = `0${mintues}`
      }

      const dformat = `${[d.getFullYear(), mes, dias].join('-')} ${[horas, mintues].join(':')}`

      return dformat
    },
  },
}
</script>
